import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  Flex,
  Image,
  Center,
  Box,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { vehiclesService } from "../../services/vehicles";
import { currencyCodes, vehicleTypes } from "../../utils/helpers";
import { IoMdClose } from "react-icons/io";

export default function PricingModal({
  isOpen,
  onOpen,
  onClose,
  vehicle,
  onError,
  onSuccess,
}) {
  const [data, setData] = useState({
    pricePerHourDay: vehicle?.pricePerHourDay,
    pricePerHourNight: vehicle?.pricePerHourNight,
    currency: vehicle?.currency,
    dailyPrice: vehicle?.dailyPrice,
    airportOneWayPrice: vehicle?.airportOneWayPrice,
    airportTwoWayPrice: vehicle?.airportTwoWayPrice,
    id: vehicle?.id,
    luggagePrice: vehicle?.luggagePrice,
    protocolPrice: vehicle?.protocolPrice,
    iconUrl: vehicle?.iconUrl,
    vehicleType: vehicle?.vehicleType,
  });
  const [loading, setLoading] = useState(false);
  const imageInput = useRef();
  const [vehicleImages, setVehicleImages] = useState([]);

  useEffect(() => {
    setData({
      pricePerHourDay: vehicle?.pricePerHourDay,
      pricePerHourNight: vehicle?.pricePerHourNight,
      dailyPrice: vehicle?.dailyPrice,
      airportOneWayPrice: vehicle?.airportOneWayPrice,
      airportTwoWayPrice: vehicle?.airportTwoWayPrice,
      currency: vehicle?.currency,
      id: vehicle?.id,
      luggagePrice: vehicle?.luggagePrice,
      protocolPrice: vehicle?.protocolPrice,
      iconUrl: vehicle?.iconUrl,
      vehicleType: vehicle?.vehicleType,
    });
    setVehicleImages([vehicle?.iconUrl]);
  }, [vehicle]);

  const update = async () => {
    try {
      const {
        pricePerHourDay,
        dailyPrice,
        airportOneWayPrice,
        airportTwoWayPrice,
        currency,
        id,
        pricePerHourNight,
        luggagePrice,
        protocolPrice,
        vehicleType,
      } = data;

      if (
        !pricePerHourDay ||
        !vehicleType ||
        !dailyPrice ||
        !airportOneWayPrice ||
        !airportTwoWayPrice ||
        !currency ||
        !pricePerHourNight ||
        !luggagePrice ||
        !protocolPrice ||
        vehicleImages?.length == 0
      ) {
        onError("All fields are required");
        return;
      }

      setLoading(true);

      const cloudinaryUrl =
        "https://api.cloudinary.com/v1_1/duldhdjsj/image/upload";
      setLoading(true);

      let uploadedImages = [];

      if (vehicleImages?.length > 0) {
        const uploadPromises = vehicleImages.map(async (image) => {
          if (image.startsWith("https://")) {
            return image;
          }

          const formData = new FormData();
          formData.append("file", image);
          formData.append("upload_preset", "wtmnkwiw");

          const response = await fetch(cloudinaryUrl, {
            method: "POST",
            body: formData,
          });
          const result = await response.json();
          return result.secure_url;
        });

        uploadedImages = await Promise.all(uploadPromises);
      }

      const res = await vehiclesService.editPricingData({
        pricePerHourDay: parseInt(pricePerHourDay),
        pricePerHourNight: parseInt(pricePerHourNight),
        currency,
        id,
        airportOneWayPrice,
        airportTwoWayPrice,
        dailyPrice,
        luggagePrice,
        protocolPrice,
        vehicleType,
        iconUrl: uploadedImages[0],
      });
      onSuccess(res?.data?.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      onError(error?.response?.data?.message || error?.message);
    }
  };

  function handleFiles(event) {
    const files = event.target.files;
    const urls = [];

    Array.from(files).forEach((file) => {
      const reader = new FileReader();

      reader.onload = function (event) {
        const imageUrl = event.target.result;
        urls.push(imageUrl);
        if (files.length == urls.length) {
          setVehicleImages([...urls]);
        }
      };

      reader.readAsDataURL(file);
    });
  }

  return (
    <>
      <Modal isCentered={true} size={"xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {vehicleTypes.find((v) => v.value == vehicle?.vehicleType)?.label}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <input
              maxLength={1}
              onChange={handleFiles}
              style={{ display: "none" }}
              type="file"
              ref={imageInput}
              accept="image/*"
            />

            <Flex columnGap={"20px"} mb={"10px"}>
              <FormControl isRequired>
                <FormLabel fontWeight="medium" fontSize="14px">
                  Vehicle Type
                </FormLabel>
                <Input
                  py={"22px"}
                  value={data?.vehicleType}
                  textTransform={"uppercase"}
                  onChange={(e) => {
                    setData({
                      ...data,
                      vehicleType: e?.target?.value?.toUpperCase(),
                    });
                  }}
                  type={"text"}
                  placeholder="Ex: ECONOMY, etc"
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Price per hour /Day</FormLabel>
                <Flex>
                  <CurrencyInput
                    className="currency-input-field"
                    id="input-example"
                    name="input-name"
                    placeholder="Price/Km"
                    defaultValue={data?.pricePerHourDay}
                    value={data?.pricePerHourDay}
                    decimalsLimit={2}
                    maxLength={20}
                    onValueChange={(value, name) => {
                      setData({
                        ...data,
                        pricePerHourDay: value ? parseInt(value) : "",
                      });
                    }}
                  />
                  <Select
                    borderLeftRadius={0}
                    h={"45px"}
                    bg="neutral.300"
                    disabled={true}
                    w={"150px"}
                    onChange={(e) => {
                      setData({ ...data, currency: e.target.value });
                    }}
                    value={data.currency}
                  >
                    <option value={""} disabled>
                      Select currency
                    </option>
                    {currencyCodes?.map((code) => {
                      return (
                        <option
                          selected={data.currency == code ? true : false}
                          value={code}
                        >
                          {code}
                        </option>
                      );
                    })}
                  </Select>
                </Flex>
              </FormControl>
            </Flex>

            <Flex columnGap={"10px"} mb={"10px"}>
              <FormControl isRequired>
                <FormLabel>Price per hour /Night</FormLabel>
                <Flex>
                  <CurrencyInput
                    className="currency-input-field"
                    id="input-example"
                    name="input-name"
                    placeholder="Price/Km"
                    defaultValue={data?.pricePerHourNight}
                    value={data?.pricePerHourNight}
                    decimalsLimit={2}
                    maxLength={20}
                    onValueChange={(value, name) => {
                      setData({
                        ...data,
                        pricePerHourNight: value ? parseInt(value) : "",
                      });
                    }}
                  />
                  <Select
                    isDisabled={true}
                    borderLeftRadius={0}
                    h={"45px"}
                    bg="neutral.300"
                    w={"150px"}
                    onChange={(e) => {
                      setData({ ...data, currency: e.target.value });
                    }}
                    value={data.currency}
                  >
                    <option value={""} disabled>
                      Select currency
                    </option>
                    {currencyCodes?.map((code) => {
                      return (
                        <option
                          selected={data.currency == code ? true : false}
                          value={code}
                        >
                          {code}
                        </option>
                      );
                    })}
                  </Select>
                </Flex>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Daily price</FormLabel>
                <Flex>
                  <CurrencyInput
                    className="currency-input-field"
                    id="input-example"
                    name="input-name"
                    placeholder="Daily price"
                    defaultValue={data?.dailyPrice}
                    value={data?.dailyPrice}
                    decimalsLimit={2}
                    maxLength={20}
                    onValueChange={(value, name) => {
                      setData({
                        ...data,
                        dailyPrice: value ? parseInt(value) : "",
                      });
                    }}
                  />
                  <Select
                    isDisabled={true}
                    borderLeftRadius={0}
                    h={"45px"}
                    bg="neutral.300"
                    w={"150px"}
                    onChange={(e) => {
                      setData({ ...data, currency: e.target.value });
                    }}
                    value={data.currency}
                  >
                    <option value={""} disabled>
                      Select currency
                    </option>
                    {currencyCodes?.map((code) => {
                      return (
                        <option
                          selected={data.currency == code ? true : false}
                          value={code}
                        >
                          {code}
                        </option>
                      );
                    })}
                  </Select>
                </Flex>
              </FormControl>
            </Flex>

            <Flex columnGap={"20px"} mb={"10px"}>
              <FormControl isRequired>
                <FormLabel>Airport one way price</FormLabel>
                <Flex>
                  <CurrencyInput
                    className="currency-input-field"
                    id="input-example"
                    name="input-name"
                    placeholder="Airport price"
                    defaultValue={data?.airportOneWayPrice}
                    value={data?.airportOneWayPrice}
                    decimalsLimit={2}
                    maxLength={20}
                    onValueChange={(value, name) => {
                      setData({
                        ...data,
                        airportOneWayPrice: value ? parseInt(value) : "",
                      });
                    }}
                  />
                  <Select
                    isDisabled={true}
                    borderLeftRadius={0}
                    h={"45px"}
                    bg="neutral.300"
                    w={"150px"}
                    onChange={(e) => {
                      setData({ ...data, currency: e.target.value });
                    }}
                    value={data.currency}
                  >
                    <option value={""} disabled>
                      Select currency
                    </option>
                    {currencyCodes?.map((code) => {
                      return (
                        <option
                          selected={data.currency == code ? true : false}
                          value={code}
                        >
                          {code}
                        </option>
                      );
                    })}
                  </Select>
                </Flex>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Airport two way price</FormLabel>
                <Flex>
                  <CurrencyInput
                    className="currency-input-field"
                    id="input-example"
                    name="input-name"
                    placeholder="Airport price"
                    defaultValue={data?.airportTwoWayPrice}
                    value={data?.airportTwoWayPrice}
                    decimalsLimit={2}
                    maxLength={20}
                    onValueChange={(value, name) => {
                      setData({
                        ...data,
                        airportTwoWayPrice: value ? parseInt(value) : "",
                      });
                    }}
                  />
                  <Select
                    isDisabled={true}
                    borderLeftRadius={0}
                    h={"45px"}
                    bg="neutral.300"
                    w={"150px"}
                    onChange={(e) => {
                      setData({ ...data, currency: e.target.value });
                    }}
                    value={data.currency}
                  >
                    <option value={""} disabled>
                      Select currency
                    </option>
                    {currencyCodes?.map((code) => {
                      return (
                        <option
                          selected={data.currency == code ? true : false}
                          value={code}
                        >
                          {code}
                        </option>
                      );
                    })}
                  </Select>
                </Flex>
              </FormControl>
            </Flex>

            <Flex columnGap={"20px"} mb={"13px"}>
              <FormControl isRequired>
                <FormLabel>Aiport luggage price</FormLabel>
                <Flex>
                  <CurrencyInput
                    className="currency-input-field"
                    id="input-example"
                    name="input-name"
                    placeholder="Airport price"
                    defaultValue={data?.luggagePrice}
                    value={data?.luggagePrice}
                    decimalsLimit={2}
                    maxLength={20}
                    onValueChange={(value, name) => {
                      setData({
                        ...data,
                        luggagePrice: value ? parseInt(value) : "",
                      });
                    }}
                  />
                  <Select
                    isDisabled={true}
                    borderLeftRadius={0}
                    h={"45px"}
                    bg="neutral.300"
                    w={"150px"}
                    onChange={(e) => {
                      setData({ ...data, currency: e.target.value });
                    }}
                    value={data.currency}
                  >
                    <option value={""} disabled>
                      Select currency
                    </option>
                    {currencyCodes?.map((code) => {
                      return (
                        <option
                          selected={data.currency == code ? true : false}
                          value={code}
                        >
                          {code}
                        </option>
                      );
                    })}
                  </Select>
                </Flex>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Airport protocol Price</FormLabel>
                <Flex>
                  <CurrencyInput
                    className="currency-input-field"
                    id="input-example"
                    name="input-name"
                    placeholder="Airport price"
                    defaultValue={data?.protocolPrice}
                    value={data?.protocolPrice}
                    decimalsLimit={2}
                    maxLength={20}
                    onValueChange={(value, name) => {
                      setData({
                        ...data,
                        protocolPrice: value ? parseInt(value) : "",
                      });
                    }}
                  />
                  <Select
                    isDisabled={true}
                    borderLeftRadius={0}
                    h={"45px"}
                    bg="neutral.300"
                    w={"150px"}
                    onChange={(e) => {
                      setData({ ...data, currency: e.target.value });
                    }}
                    value={data.currency}
                  >
                    <option value={""} disabled>
                      Select currency
                    </option>
                    {currencyCodes?.map((code) => {
                      return (
                        <option
                          selected={data.currency == code ? true : false}
                          value={code}
                        >
                          {code}
                        </option>
                      );
                    })}
                  </Select>
                </Flex>
              </FormControl>
            </Flex>

            <FormControl isRequired mb={"10px"}>
              <FormLabel fontWeight="medium" fontSize="14px" mb={"2px"}>
                Upload vehicle image
              </FormLabel>
              <Box
                py={"30px"}
                px={"30px"}
                onClick={() => {
                  imageInput.current.click();
                }}
                borderRadius={"12px"}
                borderStyle={"dashed"}
                borderWidth={3}
                borderColor={"lightgray"}
                bg={"#F8FAFB"}
                mt={"5px"}
                textAlign={"center"}
                cursor={"pointer"}
              >
                <Button colorScheme={"primary"}>Browse</Button>
              </Box>
              <Box
                display={"flex"}
                flexWrap={"wrap"}
                columnGap={"10px"}
                rowGap={"10px"}
                pt={"10px"}
              >
                {vehicleImages?.map((img, index) => {
                  return (
                    <Center
                      pos={"relative"}
                      width={"150px"}
                      height={"150px"}
                      key={"img" + index}
                      boxShadow={"xs"}
                    >
                      <Image
                        borderRadius={"4px"}
                        objectFit={"contain"}
                        maxHeight={"100%"}
                        maxWidth={"100%"}
                        objectPosition={"top"}
                        src={img}
                      />
                      <button
                        onClick={() => {
                          setVehicleImages(
                            vehicleImages.filter((imgs) => imgs != img)
                          );
                        }}
                        type="button"
                        className="closeBtn"
                      >
                        <IoMdClose size={20} />
                      </button>
                    </Center>
                  );
                })}
              </Box>
            </FormControl>

            <ModalFooter>
              <Button onClick={onClose} mr={3}>
                Cancel
              </Button>
              <Button
                isLoading={loading}
                onClick={update}
                colorScheme="primary"
              >
                Save
              </Button>
            </ModalFooter>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
