import {
  Box,
  Container,
  Flex,
  Link,
  Stack,
  Text,
  IconButton,
  Image,
  VStack,
} from "@chakra-ui/react";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import playStore from "../../assets/images/googleplay-fr.png";
import appStore from "../../assets/images/appstore-fr.png";
import playStoreEn from "../../assets/images/googleplay.png";
import appStoreEn from "../../assets/images/appstore.png";
import logo from "../../assets/images/white-logo.png";
import { t } from "i18next";
import i18n from "../../utils/i18n";

const Footer = () => {
  return (
    <Box className="appfooter" bg="gray.900" color="white" py={8}>
      <Container maxW="container.xl">
        <Flex
          direction={{ base: "column", md: "row" }}
          justify="space-between"
          align="flex-start"
          wrap="wrap"
        >
          {/* Logo and Social Links */}
          <VStack align="flex-start" mb={{ base: 6, md: 0 }} spacing={4}>
            <Image
              objectFit={"contain"}
              src={logo}
              width={"100px"}
              alt="Bolt Logo"
              ml={"-5px"}
              mb={"5px"}
            />
            <Flex gap={4}>
              <IconButton
                as="a"
                href="#"
                aria-label="Facebook"
                icon={<FaFacebook />}
                bg="gray.700"
                _hover={{ bg: "teal.500" }}
              />
              <IconButton
                as="a"
                href="#"
                aria-label="Twitter"
                icon={<FaTwitter />}
                bg="gray.700"
                _hover={{ bg: "teal.500" }}
              />
              <IconButton
                as="a"
                href="#"
                aria-label="Instagram"
                icon={<FaInstagram />}
                bg="gray.700"
                _hover={{ bg: "teal.500" }}
              />
            </Flex>
          </VStack>

          {/* Links */}
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={6}
            align={{ base: "flex-start", md: "center" }}
            flex="1"
            justify="center"
          >
            <Link
              href="/"
              _hover={{ textDecoration: "underline", color: "teal.300" }}
            >
              {t("home")}
            </Link>
            <Link
              onClick={() => {
                const btn = document.getElementById("openqrcode");
                if (btn) {
                  btn.click();
                }
              }}
              _hover={{ textDecoration: "underline", color: "teal.300" }}
            >
              {t("getTheApp")}
            </Link>
            <Link
              href="/"
              _hover={{ textDecoration: "underline", color: "teal.300" }}
            >
              {t("about")}
            </Link>
            <Link
              href="/policies/terms"
              _hover={{ textDecoration: "underline", color: "teal.300" }}
            >
              {t("privacyPolicy")}
            </Link>
            <Link
              href="/contact-us/"
              _hover={{ textDecoration: "underline", color: "teal.300" }}
            >
              {t("help")}
            </Link>
          </Stack>

          {/* App Store and Google Play Buttons */}
          <VStack align="flex-start" spacing={4} mt={{ base: 6, md: 0 }}>
            <Text fontWeight="bold" fontSize="lg">
              {t("donwloadApp")}:
            </Text>
            <Flex gap={4}>
              <Link
                href="https://play.google.com/store/apps/details?id=com.apps.wana&hl=en"
                isExternal
              >
                <Image
                  src={i18n.language == "en" ? playStoreEn : playStore}
                  alt="Get it on Google Play"
                  height="50px"
                />
              </Link>
              <Link
                href="https://apps.apple.com/rw/app/wana/id6740150377"
                isExternal
              >
                <Image
                  src={i18n.language == "en" ? appStoreEn : appStore}
                  alt="Download on the App Store"
                  height="50px"
                />
              </Link>
            </Flex>
          </VStack>
        </Flex>

        {/* Copyright */}
        <Box mt={8} borderTop="1px solid" borderColor="gray.700" pt={4}>
          <Text textAlign="center" fontSize="sm">
            © {new Date().getFullYear()} {t("copyright")}
          </Text>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
