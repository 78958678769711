import {
  Box,
  Link,
  Heading,
  Text,
  OrderedList,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import AppNavBar from "./navbar";
import Footer from "./footer";

export default function Tos() {
  const { t } = useTranslation();

  return (
    <Box className="w-full">
      <AppNavBar currentPage={"tos"} color={"rgb(28 36 52)"} />

      <Box className="tospagecontainer" px={"150px"} mb={"50px"}>
        <Box my={6} mb={4} py={4}>
          <Heading as="h2" size="xl" mb={3}>
            {t("tos.title")}
          </Heading>
        </Box>

        <OrderedList pl={"5px"} className="toslist" spacing={4}>
          {[
            "services_proposed",
            "platform_usage",
            "payment",
            "responsibility",
            "user_behavior",
            "service_changes",
            "personal_data",
            "termination",
            "disputes",
          ].map((key) => (
            <ListItem key={key}>
              <Heading as="h3" fontSize="24px" mb={2}>
                {t(`tos.${key}.heading`)}
              </Heading>
              <Text>{t(`tos.${key}.content`)}</Text>
            </ListItem>
          ))}
        </OrderedList>

        <Box mt={6} mb={5} py={4}>
          <Heading as="h2" size="xl" mb={3}>
            {t("tos.privacy_policy.title")}
          </Heading>
          <Text fontSize="base">{t("tos.privacy_policy.intro")}</Text>
        </Box>

        <OrderedList pl={"5px"} className="toslist" spacing={4}>
          {[
            "data_collected",
            "data_usage",
            "data_sharing",
            "security",
            "your_rights",
          ].map((key) => (
            <ListItem key={key}>
              <Heading as="h3" fontSize="24px" mb={2}>
                {t(`tos.privacy_policy.${key}.heading`)}
              </Heading>
              <UnorderedList pl={"5px"} className="tuslist" spacing={4}>
                {t(`tos.privacy_policy.${key}.items`, {
                  returnObjects: true,
                }).map((item, index) => (
                  <ListItem key={index}>
                    <Text>{item}</Text>
                  </ListItem>
                ))}
              </UnorderedList>
            </ListItem>
          ))}
        </OrderedList>

        <Box mt={6}>
          <Text mb={"10px"}>
            {t("tos.privacy_policy.contact_us")}{" "}
            <Link color="dodgerblue" href="mailto:apps.wana@gmail.com">
              apps.wana@gmail.com
            </Link>
          </Text>
          <Text fontSize="base" fontStyle={"italic"} color="gray.600">
            {t("tos.privacy_policy.last_update")}
          </Text>
        </Box>
      </Box>

      <Footer />
    </Box>
  );
}
