import {
  Box,
  Link,
  Heading,
  Text,
  Input,
  Textarea,
  Button,
  VStack,
  HStack,
  FormControl,
  FormLabel,
  FormHelperText,
  Icon,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AppNavBar from "./navbar";
import Footer from "./footer";

export default function Contactus() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Simulate form submission logic (e.g., API call)
    setTimeout(() => {
      alert(t("form.alertMessage"));
      setIsSubmitting(false);
      setFormData({ name: "", email: "", message: "" });
    }, 2000);
  };

  return (
    <Box bg={"white"} className="w-full">
      <AppNavBar currentPage={"/contact-us"} color={"rgb(28 36 52)"} />

      <Box
        className="contact-us-page"
        px={{ base: "20px", md: "150px" }}
        py={{ base: 8, md: 16 }}
      >
        <Box style={{ flex: 1 }}>
          <Box mb={10}>
            <Heading
              as="h2"
              size="2xl"
              mb={3}
              className="contact-header"
              color={useColorModeValue("black", "white")}
            >
              {t("page.title")}
            </Heading>
            <Text
              fontSize="lg"
              color={useColorModeValue("gray.600", "gray.400")}
            >
              {t("page.description")}
            </Text>
          </Box>

          <Box
            spacing={8}
            mb={16}
            justify="center"
            alignItems="center"
            flexWrap="wrap"
          >
            <HStack
              spacing={4}
              bg="blue.50"
              p={4}
              borderRadius="lg"
              boxShadow="sm"
              onClick={() => {
                window.open("mailto:apps.wana@gmail.com");
              }}
              mb={"10px"}
              cursor={"pointer"}
            >
              <Icon as={FaEnvelope} color="blue.500" boxSize={6} />
              <Text fontSize="lg" color="gray.700">
                {t("contact.email")}
              </Text>
            </HStack>

            <HStack
              spacing={4}
              bg="blue.50"
              p={4}
              borderRadius="lg"
              cursor={"pointer"}
              onClick={() => {
                window.open("tel:+254745507370");
              }}
              boxShadow="sm"
            >
              <Icon as={FaPhoneAlt} color="blue.500" boxSize={6} />
              <Text fontSize="lg" color="gray.700">
                {t("contact.phone")}
              </Text>
            </HStack>
          </Box>
        </Box>

        <Box
          style={{ flex: 1 }}
          // maxW="600px"
          mx="auto"
          p={8}
          // bg={useColorModeValue("white", "gray.700")}
          borderRadius="lg"
          boxShadow="lg"
        >
          <form onSubmit={handleSubmit}>
            <VStack spacing={6}>
              <FormControl isRequired>
                <FormLabel color={useColorModeValue("gray.800", "gray.200")}>
                  {t("form.name.label")}
                </FormLabel>
                <Input
                  type="text"
                  placeholder={t("form.name.placeholder")}
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  focusBorderColor="blue.500"
                  variant="filled"
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel color={useColorModeValue("gray.800", "gray.200")}>
                  {t("form.email.label")}
                </FormLabel>
                <Input
                  type="email"
                  placeholder={t("form.email.placeholder")}
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  focusBorderColor="blue.500"
                  variant="filled"
                />
                <FormHelperText>{t("form.email.helperText")}</FormHelperText>
              </FormControl>

              <FormControl isRequired>
                <FormLabel color={useColorModeValue("gray.800", "gray.200")}>
                  {t("form.message.label")}
                </FormLabel>
                <Textarea
                  placeholder={t("form.message.placeholder")}
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  focusBorderColor="blue.500"
                  variant="filled"
                />
              </FormControl>

              <Button
                type="submit"
                colorScheme="primary"
                width="full"
                isLoading={isSubmitting}
                loadingText={t("form.submitting")}
                _hover={{ bg: "blue.600" }}
                _active={{ bg: "blue.700" }}
              >
                {t("form.submitButton")}
              </Button>
            </VStack>
          </form>
        </Box>
      </Box>

      <Footer />
    </Box>
  );
}
