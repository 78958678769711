import {
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Box,
  Center,
  Skeleton,
  Image,
  Flex,
  MenuList,
  MenuItem,
  Menu,
  MenuButton,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { RiDatabase2Fill } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import TablePagination from "../../components/common/tablePagination";
import { vehicleTypes } from "../../utils/helpers";
import { TbDotsVertical } from "react-icons/tb";
import AssignDriverModal from "./assignDriverModal";
import moment from "moment/moment";
import RegisterVehicleModal from "./registerVehicleModal";
import WarningModal from "../../components/common/warning-modal";
import { vehiclesService } from "../../services/vehicles";

const VehiclesTable = ({
  headers,
  data,
  loading,
  totalNum,
  pageNum,
  setSortBy,
  searching,
  itemsPerPage,
  onReload,
}) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [vehicle, setVehicle] = useState({});
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [closing, setClosing] = useState(false);
  const toast = useToast();
  useEffect(() => {
    setCurrentPage(pageNum);
  }, [pageNum]);

  const pagNation = useRef();

  const rows = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const [showModal, setShowModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [modalAction, setModalAction] = useState("edit");
  const [isClosing, setIsClosing] = useState(false);

  const openModal = (action, vehicleData = null) => {
    setModalAction(action);
    setVehicle(vehicleData);
    setShowModal(true);
  };

  const closeShift = async () => {
    try {
      setIsClosing(true);
      const res = await vehiclesService.closeShift({
        driverId: vehicle?.user?.id,
        vehicleId: vehicle?.id,
      });
      setIsClosing(false);
      setShowWarningModal(false);
      setShowModal(false);
      onReload();
    } catch (error) {
      setIsClosing(false);
      toast({
        title: "Error",
        description:
          error?.response?.data?.message ||
          "An error occured, please try again",
        status: "error",
        duration: 5000,
      });
    }
  };

  return (
    <Box minH={"60vh"}>
      <Table className="customers-table">
        <Thead>
          <Tr>
            {headers?.map((header, index) => (
              <Th textTransform={"none"}>
                <Text fontWeight={"bold"}>{header?.name} </Text>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {loading ? (
            rows?.map((r) => {
              return (
                <>
                  <Tr>
                    {Array.from(
                      { length: headers?.length },
                      (_, i) => i + 1
                    )?.map((d) => {
                      return (
                        <Td>
                          <Box py={"7px"}>
                            <Skeleton
                              startColor={"#F4F4F4"}
                              borderRadius={"20px"}
                              endColor={"#dddddd"}
                              h={"20px"}
                            />
                          </Box>
                        </Td>
                      );
                    })}
                  </Tr>
                </>
              );
            })
          ) : (
            <>
              {!loading && data?.length == 0 ? (
                <>
                  <Tr _hover={{ bg: "white !important" }}>
                    <Td colSpan={12} bg={"white"} _hover={{ bg: "white" }}>
                      <Center bg={"white"} py={"45px"} w={"100%"} minW={"100%"}>
                        {searching ? (
                          <Text>No results found</Text>
                        ) : (
                          <>
                            <Center>
                              <Box textAlign={"center"}>
                                <Center mb={"18px"}>
                                  <Box
                                    py={6}
                                    px={6}
                                    borderRadius={"10px"}
                                    bg={"#F2F4F6"}
                                  >
                                    <RiDatabase2Fill
                                      color={"#bcc5d1"}
                                      size={35}
                                    ></RiDatabase2Fill>
                                  </Box>
                                </Center>
                                <Text
                                  fontSize={"19px"}
                                  fontWeight={500}
                                  mb={"9px"}
                                  color={"#637184"}
                                >
                                  Vehicles
                                </Text>
                                <Text fontSize={"14px"} color="text.lightest">
                                  No vehicles available, once they are available
                                  they will appear right here.
                                </Text>
                              </Box>
                            </Center>
                          </>
                        )}
                      </Center>
                    </Td>
                  </Tr>
                </>
              ) : (
                <>
                  {data?.map((data) => {
                    return (
                      <Tr
                        cursor={"pointer"}
                        onClick={() => {
                          openModal("edit", data);
                        }}
                      >
                        <Td>
                          <Flex alignItems={"center"} columnGap={"10px"}>
                            <Image
                              w={"60px"}
                              height={"50px"}
                              objectFit={"contain"}
                              src={data?.images[0]}
                            />
                            <Box>
                              <Text>{data?.vehicle?.vehicleType}</Text>
                              <Text color="neutral.700">
                                {(data?.brand ? data?.brand : "") +
                                  (data?.brand ? ", " : "") +
                                  data?.model}
                              </Text>
                            </Box>
                          </Flex>
                        </Td>
                        <Td>{data?.plateNumber}</Td>
                        <Td>{data?.color}</Td>
                        <Td>
                          {data?.user ? (
                            <Box>
                              <Text>{data?.user?.fullName}</Text>
                              <Text color="neutral.700">
                                {data?.user?.phoneNumber}
                              </Text>
                            </Box>
                          ) : (
                            <Box>
                              <Text>N/A</Text>
                            </Box>
                          )}
                        </Td>
                        <Td>
                          {data?.user?.shiftStartDate ? (
                            <Box>
                              <Text>
                                {moment(
                                  new Date(data?.user?.shiftStartDate)
                                ).format("DD/MM/YY hh:mm A")}
                              </Text>
                              <Text ml={"45px"}>{" | "}</Text>
                              <Text>
                                {data?.user?.shiftEndDate
                                  ? moment(
                                      new Date(data?.user?.shiftEndDate)
                                    ).format("DD/MM/YY hh:mm A")
                                  : "Shift not yet ended"}
                              </Text>
                            </Box>
                          ) : (
                            " - "
                          )}
                        </Td>
                        <Td>
                          {moment(new Date(data?.createdAt)).format(
                            "DD/MM/YY hh:mm A"
                          )}
                        </Td>
                        <Td onClick={(e) => e.stopPropagation()}>
                          <Menu>
                            <MenuButton bg={"transparent"} as={Button}>
                              <TbDotsVertical size={22} />
                            </MenuButton>
                            <MenuList>
                              <MenuItem
                                onClick={() => {
                                  setVehicle(data);
                                  if (!data?.user) {
                                    setIsModalVisible2(true);
                                  } else {
                                    setShowWarningModal(true);
                                  }
                                }}
                              >
                                {data?.user ? "Close shift" : "Assign Driver"}
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  openModal("edit", data);
                                }}
                              >
                                View more
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    );
                  })}
                </>
              )}
            </>
          )}
        </Tbody>
      </Table>

      {totalNum > itemsPerPage ? (
        <Box ref={pagNation} className="pag-cont bottom-0 w-full pb-20">
          <TablePagination
            length={totalNum}
            initialPage={parseInt(currentPage)}
            currentItems={data?.length}
            pageNum={pageNum}
            itemsPerPage={itemsPerPage}
            setPage={(page) => {
              setCurrentPage(parseInt(page));
              navigate(`/vehicles?page=${page}`);
            }}
          />
        </Box>
      ) : (
        <></>
      )}

      {isModalVisible2 && (
        <AssignDriverModal
          isOpen={isModalVisible2}
          vehicleId={vehicle?.id}
          onClose={() => {
            setIsModalVisible2(false);
          }}
          onSuccess={(message) => {
            setIsModalVisible2(false);
            onReload();
            toast({
              position: "top",
              title: "Success",
              status: "success",
              description: message,
              isClosable: true,
              duration: 2000,
            });
          }}
          onError={(message) => {
            toast({
              position: "top",
              title: "Error",
              status: "error",
              description: message,
              isClosable: true,
              duration: 2000,
            });
          }}
        />
      )}

      {showModal && (
        <RegisterVehicleModal
          action={modalAction}
          currentVehicle={vehicle}
          isOpen={showModal}
          onChangeDriver={() => {
            setShowModal(false);
            setIsModalVisible2(true);
          }}
          onError={(message) => {
            toast({
              position: "top",
              title: "Error",
              status: "error",
              description: message,
              isClosable: true,
              duration: 2000,
            });
          }}
          onRegister={() => {
            onReload();
            setShowModal(false);
            toast({
              position: "top",
              title: "Success",
              status: "success",
              description: "Vehicle edited successfully!",
              isClosable: true,
              duration: 2000,
            });
          }}
          onClose={() => setShowModal(false)}
          onCloseShift={() => {
            setShowWarningModal(true);
          }}
        />
      )}

      {showWarningModal && (
        <WarningModal
          isOpen={showWarningModal}
          onClose={() => {
            setShowWarningModal(false);
          }}
          isLoading={isClosing}
          title={"Close shift"}
          description={"Are you sure you want to close this shift?"}
          btnText={"Confirm"}
          onConfirm={() => {
            closeShift();
          }}
        />
      )}
    </Box>
  );
};

export default VehiclesTable;
