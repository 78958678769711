import AppRoutes from "./routes";
import "./styles/main.css";
import "./styles/sidebar.css";
import "./styles/dashboard.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/home";
import Tos from "./pages/home/tos";
import Contactus from "./pages/home/contactus";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
        </Routes>
      </BrowserRouter>

      <BrowserRouter basename="/policies">
        <Routes>
          <Route path="/terms" element={<Tos />} />
        </Routes>
      </BrowserRouter>

      <BrowserRouter basename="/contact-us">
        <Routes>
          <Route path="/" element={<Contactus />} />
        </Routes>
      </BrowserRouter>

      <BrowserRouter basename="/admin">
        <AppRoutes />
      </BrowserRouter>
    </>
  );
}

export default App;
