import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Authentication/Login";
import AppLayout from "./components/common/app-layout";
import Admins from "./pages/admins";
import Drivers from "./pages/drivers";
import Logout from "./logout";
import Dashboard from "./pages/dashboard";
import Trips from "./pages/trips";
import Passengers from "./pages/passengers";
import DriverPerformance from "./pages/drivers/driverPerformance";
import ForgotPassword from "./pages/Authentication/forgot-password";
import NewPassword from "./pages/Authentication/new-password";
import Settings from "./pages/settings";
import Vehicles from "./pages/vehicles";
import Finance from "./pages/finance";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/new-password" element={<NewPassword />} />
      <Route
        path="/users"
        element={
          <AppLayout>
            <Admins />
          </AppLayout>
        }
      />
      <Route
        path="/dashboard"
        element={
          <AppLayout>
            <Dashboard />
          </AppLayout>
        }
      />
      <Route
        path="/vehicles"
        element={
          <AppLayout>
            <Vehicles />
          </AppLayout>
        }
      />
      <Route
        path="/finance"
        element={
          <AppLayout>
            <Finance />
          </AppLayout>
        }
      />
      <Route
        path="/settings"
        element={
          <AppLayout>
            <Settings />
          </AppLayout>
        }
      />
      <Route
        path="/drivers"
        element={
          <AppLayout>
            <Drivers />
          </AppLayout>
        }
      />
      <Route
        path="/drivers/performance/:id"
        element={
          <AppLayout showBackButton={true}>
            <DriverPerformance />
          </AppLayout>
        }
      />
      <Route
        path="/passengers"
        element={
          <AppLayout>
            <Passengers />
          </AppLayout>
        }
      />
      <Route
        path="/trips"
        element={
          <AppLayout>
            <Trips />
          </AppLayout>
        }
      />
    </Routes>
  );
}
