import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { TbDotsVertical } from "react-icons/tb";
import PricingModal from "../../components/common/pricingModal";
import { vehiclesService } from "../../services/vehicles";
import { vehicleTypes } from "../../utils/helpers";
import { adminService } from "../../services/admins";
import DeletePricingDataModal from "./deleteModal";
import { FiPlus } from "react-icons/fi";
import AddPricingModal from "./addPricingModal";

const PricingInfo = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentVehicle, setCurrentVehicle] = useState({});
  const [loading, setLoading] = useState(true);
  const tds = [1, 2, 3, 4, 5, 6, 7, 8, 9,10];
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const rows = [1, 2, 3, 4];
  const [pricing, setPricingData] = useState([]);
  const toast = useToast();
  const [data, setData] = useState({});

  const getPricingData = async () => {
    try {
      setLoading(true);
      const res = await vehiclesService.getPricingData(true);
      setPricingData(res?.data?.pricingData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPricingData();
  }, []);

  return (
    <>
      <Box mx="0px" pt={"0px"}>
        <Box className="table-nav"></Box>
        <Flex rowGap={"40px"}>
          <Box w={"100%"} bg={"white"} py={"20px"} px={"20px"}>
            <Flex
              mb={"15px"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Text className="tb-title">Pricing Info</Text>

              <Button
                variant="ghost"
                onClick={() => {
                  setIsModalVisible2(true);
                }}
                colorScheme="blue"
              >
                <FiPlus size={21} />
                Add
              </Button>
            </Flex>

            <Table className="customers-table drivers-table pricing-table">
              <Thead px={"0px"}>
                <Tr>
                  <Th textTransform={"none"}>
                    <Text fontWeight={"bold"} fontSize={14}>
                      Vehicle
                    </Text>
                  </Th>
                  <Th textTransform={"none"}>
                    <Text fontWeight={"bold"} fontSize={14}>
                      Hours/Day
                    </Text>
                  </Th>
                  <Th textTransform={"none"}>
                    <Text fontWeight={"bold"} fontSize={14}>
                      Hours/Night
                    </Text>
                  </Th>
                  <Th textTransform={"none"}>
                    <Text fontWeight={"bold"} fontSize={14}>
                      Daily
                    </Text>
                  </Th>
                  <Th textTransform={"none"}>
                    <Text fontWeight={"bold"} fontSize={14}>
                      Airport
                      <br />
                      (One Way)
                    </Text>
                  </Th>
                  <Th textTransform={"none"}>
                    <Text fontWeight={"bold"} fontSize={14}>
                      Airport
                      <br />
                      (Two Way)
                    </Text>
                  </Th>
                  <Th textTransform={"none"}>
                    <Text fontWeight={"bold"} fontSize={14}>
                      Luggage
                      <br />
                      Price
                    </Text>
                  </Th>
                  <Th textTransform={"none"}>
                    <Text fontWeight={"bold"} fontSize={14}>
                      Protocol
                      <br />
                      Price
                    </Text>
                  </Th>
                  <Th textTransform={"none"}>
                    <Text fontWeight={"bold"} fontSize={14}>
                      Status
                    </Text>
                  </Th>
                  <Th textTransform={"none"}>
                    <Text fontWeight={"bold"} fontSize={14}>
                      Action
                    </Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {loading ? (
                  rows?.map((r) => {
                    return (
                      <>
                        <Tr>
                          {tds?.map((d) => {
                            return (
                              <Td>
                                <Box py={"7px"}>
                                  <Skeleton
                                    startColor={"#F4F4F4"}
                                    borderRadius={"20px"}
                                    endColor={"#dddddd"}
                                    h={"20px"}
                                  />
                                </Box>
                              </Td>
                            );
                          })}
                        </Tr>
                      </>
                    );
                  })
                ) : (
                  <>
                    {!loading && pricing?.length == 0 ? (
                      <>
                        <Tr _hover={{ bg: "white !important" }}>
                          <Td
                            colSpan={12}
                            bg={"white"}
                            _hover={{ bg: "white" }}
                          >
                            <Center
                              bg={"white"}
                              py={"45px"}
                              w={"100%"}
                              minW={"100%"}
                            >
                              <Center>
                                <Text fontSize={"14px"} color="text.lightest">
                                  No pricing & timing data available
                                </Text>
                              </Center>
                            </Center>
                          </Td>
                        </Tr>
                      </>
                    ) : (
                      <>
                        {pricing?.map((data) => {
                          return (
                            <Tr
                              bg={
                                currentVehicle?.id == data?.id && isModalVisible
                                  ? data?.status == "ACTIVE"
                                    ? "#ffe5ea"
                                    : "#c6f7d2"
                                  : "white"
                              }
                              cursor={"pointer"}
                              onClick={() => {
                                setCurrentVehicle(data);
                                onOpen();
                              }}
                            >
                              <Td>
                                <Flex columnGap={"6px"} alignItems={"center"}>
                                  <Flex
                                    alignItems={"center"}
                                    width={"50px"}
                                    height={"50px"}
                                  >
                                    <Image
                                      objectFit={"contain"}
                                      maxWidth={"100%"}
                                      maxHeight={"100%"}
                                      src={data?.iconUrl}
                                    />
                                  </Flex>
                                  {data?.vehicleType}
                                </Flex>
                              </Td>
                              <Td>
                                {data?.currency == "USD"
                                  ? "$"
                                  : data?.currency + " "}
                                {data?.pricePerHourDay?.toLocaleString()}
                              </Td>
                              <Td>
                                {data?.currency == "USD"
                                  ? "$"
                                  : data?.currency + " "}
                                {data?.pricePerHourNight?.toLocaleString()}
                              </Td>
                              <Td>
                                {data?.currency == "USD"
                                  ? "$"
                                  : data?.currency + " "}
                                {data?.dailyPrice?.toLocaleString()}
                              </Td>
                              <Td>
                                {data?.currency == "USD"
                                  ? "$"
                                  : data?.currency + " "}
                                {data?.airportOneWayPrice?.toLocaleString()}
                              </Td>
                              <Td>
                                {data?.currency == "USD"
                                  ? "$"
                                  : data?.currency + " "}
                                {data?.airportTwoWayPrice?.toLocaleString()}
                              </Td>
                              <Td>
                                {data?.currency == "USD"
                                  ? "$"
                                  : data?.currency + " "}
                                {data?.luggagePrice?.toLocaleString()}
                              </Td>
                              <Td>
                                {data?.currency == "USD"
                                  ? "$"
                                  : data?.currency + " "}
                                {data?.protocolPrice?.toLocaleString()}
                              </Td>
                              <Td>
                                <Badge
                                  textTransform={"capitalize"}
                                  color={"#fff"}
                                  bg={
                                    data?.status == "ACTIVE"
                                      ? "green"
                                      : "crimson"
                                  }
                                >
                                  {data?.status == "DELETED"
                                    ? "DISABLED"
                                    : data?.status}
                                </Badge>
                              </Td>
                              <Td onClick={(e) => e.stopPropagation()}>
                                <Menu>
                                  <MenuButton bg={"transparent"} as={Button}>
                                    <TbDotsVertical size={22} />
                                  </MenuButton>
                                  <MenuList>
                                    <MenuItem
                                      onClick={() => {
                                        setCurrentVehicle(data);
                                        onOpen();
                                      }}
                                    >
                                      Edit
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        setCurrentVehicle(data);
                                        setIsModalVisible(true);
                                      }}
                                    >
                                      {data?.status == "DELETED"
                                        ? "Enable"
                                        : "Disable"}
                                    </MenuItem>
                                  </MenuList>
                                </Menu>
                              </Td>
                            </Tr>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </Tbody>
            </Table>
          </Box>
        </Flex>

        {isOpen && (
          <PricingModal
            isOpen={isOpen}
            vehicle={currentVehicle}
            onOpen={onOpen}
            onClose={onClose}
            onSuccess={(message) => {
              toast({
                position: "top",
                description: message,
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              onClose();
              getPricingData();
            }}
            onError={(message) => {
              toast({
                position: "top",
                title: "Error",
                status: "error",
                description: message,
                isClosable: true,
                duration: 2000,
              });
            }}
          />
        )}

        {isModalVisible && (
          <DeletePricingDataModal
            status={currentVehicle?.status}
            isOpen={isModalVisible}
            onOpen={() => {
              setIsModalVisible(true);
            }}
            onClose={() => {
              setIsModalVisible(false);
            }}
            priceId={currentVehicle?.id}
            onSuccess={(message) => {
              toast({
                position: "top",
                title: "Success",
                status: "success",
                description: message,
                isClosable: true,
                duration: 2000,
              });
              setIsModalVisible(false);
              getPricingData();
            }}
            onError={(message) => {
              toast({
                position: "top",
                title: "Error",
                status: "error",
                description: message,
                isClosable: true,
                duration: 2000,
              });
            }}
          />
        )}

        {isModalVisible2 && (
          <AddPricingModal
            isOpen={isModalVisible2}
            onClose={() => {
              setIsModalVisible2(false);
            }}
            onSuccess={(message) => {
              toast({
                position: "top",
                description: message,
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              setIsModalVisible2(false);
              getPricingData();
            }}
            onError={(message) => {
              toast({
                position: "top",
                title: "Error",
                status: "error",
                description: message,
                isClosable: true,
                duration: 2000,
              });
            }}
          />
        )}
      </Box>
    </>
  );
};

export default PricingInfo;
