import { useEffect, useState } from "react";
import { CiFilter, CiSearch } from "react-icons/ci";
import {
  Text,
  Input,
  useToast,
  Box,
  Center,
  Button,
  MenuButton,
  Menu,
  Flex,
  MenuItem,
  MenuList,
  IconButton,
} from "@chakra-ui/react";
import { AiFillPlusCircle } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { vehiclesService } from "../../services/vehicles";
import FinanceTable from "./finance-table";

const Finance = () => {
  const itemsPerPage = 10;
  const location = useLocation();
  const [pageNum, setPageNum] = useState(1);
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const toast = useToast();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Vehicles, setVehicles] = useState([]);
  const [totalNum, setTotalNum] = useState(0);
  const [searchKey, setSearchKey] = useState(null);
  const [option, setOption] = useState("list");

  const options = [
    {
      label: "List",
      value: "list",
    },
    {
      label: "Today's shifts",
      value: "shifts",
    },
    {
      label: "Free vehicles",
      value: "free",
    },
  ];

  useEffect(() => {
    const page = searchParams.get("page");
    const key = searchParams.get("searchKey");
    if (key) {
      setSearchKey(key);
    } else {
      setSearchKey(null);
    }
    if (parseInt(page) > 0) {
      return setPageNum(page);
    }
    return navigate("/finance?page=1");
  }, [searchParams]);

  const headers = [
    {
      name: "Previous Vehicle",
    },
    {
      name: "Driver",
    },
    {
      name: "Amount to deposit",
    },
    {
      name: "Shift date",
    },
    {
      name: "Last deposit",
    },
    {
      name: "Status",
    },
    {
      name: "Action",
    },
  ];

  const getVehicles = async (limit, pageNum, option) => {
    try {
      setLoading(true);
      const res = await vehiclesService.getAllVehicles(
        limit,
        pageNum,
        "NOT_DEPOSITED",
        searchKey
      );
      console.log(res?.data);
      setVehicles(res?.data?.vehicles);
      setTotalNum(res.data?.totalVehicles);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: "Failed",
        description:
          error?.response?.data?.message || "Failed to retrieve Vehicles",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    getVehicles(itemsPerPage, pageNum, option);
  }, [pageNum, searchKey, option]);

  return (
    <>
      <Box pl="25px" pr="25px" mx="0px" pt={"30px"}>
        <Box className="table-nav">
          <Box mb={"10px"} className="tb-title">
            <Text>Finance</Text>
          </Box>

          <Box className="search-cont-panel">
            <Center mb={"10px"} className="flex">
              <Input
                bg="#ffff"
                borderColor="#ffff"
                placeholder="Search..."
                colorScheme="primary"
                type={"text"}
                boxShadow="xs"
                className="search-field"
                onChange={(e) => {
                  if (e.target.value) {
                    navigate("/finance?page=1&searchKey=" + e.target.value);
                  } else {
                    navigate("/finance?page=1");
                  }
                }}
              />
              <Text zIndex={9999} right={9} position={"absolute"}>
                <CiSearch size={20} />
              </Text>
            </Center>

            {/* <Box>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  h={"100% !important"}
                  fontSize={"16px"}
                  mr={"20px"}
                  paddingBottom={"0px"}
                  mt={"-4.5px"}
                  backgroundColor={"transparent !important"}
                  borderRadius={"0px !important"}
                  borderRight={"none !important"}
                  borderBottom={"none !important"}
                  border={"none !important"}
                  variant="outline"
                >
                  <Flex
                    py={"10px"}
                    px={"15px"}
                    bg={"white"}
                    borderRadius={"10px"}
                    borderColor={"#F8FAFB"}
                    borderWidth={1}
                    alignItems={"center"}
                    columnGap={"10px"}
                  >
                    <Text>
                      {
                        options?.find((_option) => _option?.value == option)
                          ?.label
                      }
                    </Text>
                    <Text>
                      <CiFilter size={20}></CiFilter>
                    </Text>
                  </Flex>
                </MenuButton>
                <MenuList boxShadow="xl" rounded="md">
                  {options?.map((_filter) => {
                    return (
                      <MenuItem
                        className={
                          option == _filter?.value ? "activeMenuList" : ""
                        }
                        onClick={() => {
                          setOption(_filter?.value);
                        }}
                        style={{ fontSize: 15 }}
                      >
                        <Flex columnGap={"15px"} alignItems={"center"}>
                          <Box
                            className={`active-dot ${
                              option == _filter?.value ? "active-dropdown" : ""
                            }`}
                          ></Box>
                          {_filter?.label}
                        </Flex>
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Menu>
            </Box> */}
          </Box>
        </Box>

        <Box className="customers-table-container w-full" marginBottom={"40px"}>
          <FinanceTable
            headers={headers}
            data={Vehicles}
            onReload={() => {
              getVehicles(itemsPerPage, pageNum, option);
            }}
            loading={loading}
            totalNum={totalNum}
            itemsPerPage={itemsPerPage}
            pageNum={pageNum}
            setSortBy={"created_at"}
            searching={false}
          />
        </Box>
      </Box>
    </>
  );
};

export default Finance;
