import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  Flex,
  Text,
  Image,
  Box,
  Radio,
  Center,
  Checkbox,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { currencyCodes } from "../../utils/helpers";
import { adminService } from "../../services/admins";

export default function DepositModal({ isOpen, onClose, driver,onFinish }) {
  const [isLoading, setIsLoading] = useState(false);
  const [currency, setCurrency] = useState("USD");
  const [data, setData] = useState({
    driverId: "",
    amountPaid: 0,
    amountToPay: 0,
    description: "",
    currency: "USD",
    clearanceAmount: undefined,
    mPessaAmount: undefined,
  });

  const depositMoney = async () => {
    try {
      setIsLoading(true);
      const res = await adminService.deposit({
        ...data,
        amountPaid:
          driver?.totalAmount -
          Number(data?.clearanceAmount || 0) +
          Number(data?.mPessaAmount || 0),
        driverId: driver?.driver?.id,
        amountToPay: driver?.totalAmount,
      });
      onFinish();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal isCentered={true} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Deposit | {"$" + driver?.totalAmount}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl mb={3}>
              <FormLabel fontWeight={"700"}>M-PESSA Amount</FormLabel>
              <Flex>
                <CurrencyInput
                  className="currency-input-field"
                  id="input-example"
                  name="input-name"
                  placeholder="Enter amount paid on M-Pessa code"
                  value={data?.mPessaAmount}
                  decimalsLimit={2}
                  maxLength={20}
                  onValueChange={(value, name) => {
                    setData({
                      ...data,
                      mPessaAmount: value ? parseInt(value) : "",
                    });
                  }}
                />
                <Select
                  borderLeftRadius={0}
                  h={"45px"}
                  bg="neutral.300"
                  w={"150px"}
                  onChange={(e) => {
                    // setCurrency(e.target.value);
                  }}
                  value={currency}
                >
                  <option value={""} disabled>
                    Select currency
                  </option>
                  {currencyCodes?.map((code) => {
                    return (
                      <option
                        selected={currency == code ? true : false}
                        value={code}
                      >
                        {code}
                      </option>
                    );
                  })}
                </Select>
              </Flex>
            </FormControl>

            <FormControl mb={3}>
              <FormLabel fontWeight={"700"}>Clearance amount</FormLabel>
              <Flex>
                <CurrencyInput
                  className="currency-input-field"
                  id="input-example"
                  name="input-name"
                  placeholder="Enter clearance amount"
                  value={data?.clearanceAmount}
                  decimalsLimit={2}
                  maxLength={20}
                  onValueChange={(value, name) => {
                    setData({
                      ...data,
                      clearanceAmount: value ? parseInt(value) : "",
                    });
                  }}
                />
                <Select
                  borderLeftRadius={0}
                  h={"45px"}
                  bg="neutral.300"
                  w={"150px"}
                  onChange={(e) => {
                    // setCurrency(e.target.value);
                  }}
                  value={currency}
                >
                  <option value={""} disabled>
                    Select currency
                  </option>
                  {currencyCodes?.map((code) => {
                    return (
                      <option
                        selected={currency == code ? true : false}
                        value={code}
                      >
                        {code}
                      </option>
                    );
                  })}
                </Select>
              </Flex>
            </FormControl>

            <FormControl>
              <FormLabel fontWeight={"700"}>Description</FormLabel>
              <Textarea
                onChange={(e) => {
                  setData({
                    ...data,
                    description: e.target.value,
                  });
                }}
                placeholder="Description"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              onClick={() => {
                depositMoney();
              }}
              colorScheme="primary"
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
